import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image as PDFImage, Font } from '@react-pdf/renderer';
import { pdf } from '@react-pdf/renderer';
import BreeSerifFont from '../fonts/BreeSerif-Regular.ttf';

// Register the font
Font.register({
  family: 'BreeSerif',
  src: BreeSerifFont
});

// Function to generate pastel colors
const generatePastelColor = (index) => {
  const hues = [0, 60, 120, 180, 240, 300]; // Red, Yellow, Green, Cyan, Blue, Magenta
  const hue = hues[index % hues.length];
  return `hsl(${hue}, 100%, 85%)`;
};

// Update styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    padding: 20,
    fontFamily: 'BreeSerif',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  halfSection: {
    width: '50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: '90%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  contentBubble: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparent white
    borderRadius: 20,
    padding: 15,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow
  },
  content: {
    fontSize: 18.48, // Increased by 10% from 16.8
    lineHeight: 1.5,
    textAlign: 'center',
    color: '#6B46C1',
    fontFamily: 'BreeSerif',
  },
  title: {
    fontSize: 36.96, // Increased by 10% from 33.6
    textAlign: 'center',
    color: '#6B46C1',
    width: '100%',
    padding: 10,
    fontFamily: 'BreeSerif',
  },
  endText: {
    fontSize: 31.68, // Increased by 10% from 28.8
    textAlign: 'center',
    color: '#6B46C1',
    width: '100%',
    padding: 10,
    fontFamily: 'BreeSerif',
  },
});

// Update getImageDataUrl function
const getImageDataUrl = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((jpegBlob) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(jpegBlob);
        }, 'image/jpeg', 0.75);
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
};

// Update MyDocument component
const MyDocument = ({ book }) => {
  const [imageDataUrls, setImageDataUrls] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const dataUrls = await Promise.all(
        book.pages.map(page => getImageDataUrl(page.imageUrl))
      );
      setImageDataUrls(dataUrls);
    };
    fetchImages();
  }, [book]);

  return (
    <Document>
      {/* Cover Page */}
      <Page size="A4" orientation="landscape" style={[styles.page, { backgroundColor: generatePastelColor(0) }]}>
        <View style={styles.section}>
          <View style={styles.halfSection}>
            <View style={[styles.contentBubble, { justifyContent: 'center', alignItems: 'center' }]}>
              <Text style={styles.title}>{book.title}</Text>
            </View>
          </View>
          <View style={styles.halfSection}>
            <View style={styles.imageContainer}>
              {imageDataUrls[0] && <PDFImage src={imageDataUrls[0]} style={styles.image} />}
            </View>
          </View>
        </View>
      </Page>

      {/* Content Pages */}
      {book.pages.map((page, index) => (
        <Page key={index} size="A4" orientation="landscape" style={[styles.page, { backgroundColor: generatePastelColor(index + 1) }]}>
          <View style={styles.section}>
            {index % 2 === 0 ? (
              <>
                <View style={styles.halfSection}>
                  <View style={styles.contentBubble}>
                    <Text style={styles.content}>{page.content}</Text>
                  </View>
                </View>
                <View style={styles.halfSection}>
                  <View style={styles.imageContainer}>
                    {imageDataUrls[index] && <PDFImage src={imageDataUrls[index]} style={styles.image} />}
                  </View>
                </View>
              </>
            ) : (
              <>
                <View style={styles.halfSection}>
                  <View style={styles.imageContainer}>
                    {imageDataUrls[index] && <PDFImage src={imageDataUrls[index]} style={styles.image} />}
                  </View>
                </View>
                <View style={styles.halfSection}>
                  <View style={styles.contentBubble}>
                    <Text style={styles.content}>{page.content}</Text>
                  </View>
                </View>
              </>
            )}
          </View>
        </Page>
      ))}

      {/* Last Page */}
      <Page size="A4" orientation="landscape" style={[styles.page, { backgroundColor: generatePastelColor(book.pages.length) }]}>
        <View style={styles.section}>
          <View style={styles.halfSection}>
            <View style={styles.imageContainer}>
              {imageDataUrls[imageDataUrls.length - 1] && (
                <PDFImage src={imageDataUrls[imageDataUrls.length - 1]} style={styles.image} />
              )}
            </View>
          </View>
          <View style={styles.halfSection}>
            <View style={[styles.contentBubble, { justifyContent: 'center', alignItems: 'center' }]}>
              <Text style={styles.endText}>The End</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const SharedBookPage = () => {
  const { bookId } = useParams();
  const [bookData, setBookData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3004';

  useEffect(() => {
    const fetchBook = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/book/${bookId}`);
        console.log('Fetched book data:', response.data);
        setBookData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching book:', err);
        setError('Failed to load the book. It might not exist or has been removed.');
        setLoading(false);
      }
    };

    fetchBook();
  }, [bookId, apiUrl]);

  const AudioPlayer = ({ audioUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(new Audio(audioUrl));

    const togglePlay = () => {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };

    useEffect(() => {
      const audio = audioRef.current;
      audio.addEventListener('ended', () => setIsPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setIsPlaying(false));
        audio.pause();
      };
    }, []);

    return (
      <button
        onClick={togglePlay}
        className="bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-full p-3 hover:from-purple-600 hover:to-pink-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50 shadow-md"
      >
        {isPlaying ? (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        )}
      </button>
    );
  };

  const handleViewPDF = () => {
    setShowPDF(true);
  };

  const handleDownloadPDF = async () => {
    if (bookData) {
      const blob = await pdf(<MyDocument book={bookData} />).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${bookData.title}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error!</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    </div>
  );

  if (!bookData) return null;

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 100%, 90%)`;
  };

  const renderCoverPage = (bookData) => {
    const backgroundColor = generatePastelColor();
    const coverImage = bookData.pages[0].imageUrl;
    const bookTitle = bookData.title || `${bookData.childName}'s Magical Adventure`;

    return (
      <motion.div
        className="page min-h-screen flex flex-col justify-center items-center p-8"
        style={{ backgroundColor }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-4xl flex flex-col items-center">
          <h1 className="text-5xl font-bold mb-8 text-center text-purple-800 font-display">{bookTitle}</h1>
          {coverImage && (
            <img src={coverImage} alt="Book Cover" className="w-full max-w-2xl rounded-lg shadow-2xl" />
          )}
        </div>
      </motion.div>
    );
  };

  const renderThemeBookPage = (page, index) => {
    const isEvenPage = index % 2 === 0;
    const backgroundColor = generatePastelColor();

    return (
      <motion.div
        key={index}
        className="page min-h-screen flex flex-col justify-center items-center p-8"
        style={{ backgroundColor }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className={`flex ${isEvenPage ? 'flex-col md:flex-row' : 'flex-col md:flex-row-reverse'} gap-8 items-center w-full max-w-4xl`}>
          <div className="w-full md:w-1/2">
            {page.imageUrl && (
              <img src={page.imageUrl} alt={`Page ${index + 1}`} className="w-full rounded-lg shadow-lg" />
            )}
          </div>
          <div className="w-full md:w-1/2">
            <div className="bg-white bg-opacity-80 p-6 rounded-xl shadow-lg">
              <p className="text-xl text-purple-800 whitespace-pre-line">{page.content}</p>
              {page.audioUrl && (
                <div className="flex justify-center mt-4">
                  <AudioPlayer audioUrl={page.audioUrl} />
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    );
  };

  const renderLastPage = (bookData) => {
    const backgroundColor = generatePastelColor();
    const lastImage = bookData.pages[bookData.pages.length - 1].imageUrl;

    return (
      <motion.div
        className="page min-h-screen flex flex-col justify-center items-center p-8"
        style={{ backgroundColor }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="w-full max-w-4xl flex flex-col items-center">
          {lastImage && (
            <img src={lastImage} alt="Final Page" className="w-full max-w-2xl rounded-lg shadow-2xl" />
          )}
          <p className="text-2xl font-bold mt-8 text-center text-purple-800 font-display">The End</p>
        </div>
      </motion.div>
    );
  };

  const renderPage = (content, bgColor) => (
    <motion.div 
      className={`page ${bgColor} min-h-screen flex flex-col justify-center items-center p-8`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {content}
    </motion.div>
  );

  // Check if bookData.pages exist (Theme book)
  if (bookData.pages && Array.isArray(bookData.pages)) {
    // Theme book rendering
    return (
      <div className="min-h-screen bg-gradient-to-br from-pink-200 to-purple-300 py-12 px-4 sm:px-6 lg:px-8">
        <div className="book-container">
          {renderCoverPage(bookData)}
          {bookData.pages.map((page, index) => renderThemeBookPage(page, index))}
          {renderLastPage(bookData)}
        </div>

        <div className="mt-8 flex justify-center space-x-4">
          <button
            onClick={handleViewPDF}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            View PDF
          </button>
          <button
            onClick={handleDownloadPDF}
            className="bg-pink-600 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded"
          >
            Download PDF
          </button>
        </div>

        {showPDF && (
          <div className="mt-8">
            <PDFViewer width="100%" height={600}>
              <MyDocument book={bookData} />
            </PDFViewer>
          </div>
        )}
      </div>
    );
  } else {
    // Original book rendering
    return (
      <div className="book-container">
        {renderPage(
          <>
            <h1 className="text-4xl font-bold mb-8">{bookData.title}</h1>
            {bookData.coverImage && <img src={bookData.coverImage} alt="Cover" className="max-w-md mb-8 rounded-lg shadow-lg" />}
          </>,
          'bg-yellow-100'
        )}

        {renderPage(
          <>
            <img src={bookData.childImage} alt="Child" className="max-w-md mb-8 rounded-lg shadow-lg" />
            <p className="text-xl text-center max-w-2xl">{bookData.childStory}</p>
          </>,
          'bg-blue-100'
        )}

        {bookData.userImage && bookData.userStory && renderPage(
          <>
            <img src={bookData.userImage} alt="User" className="max-w-md mb-8 rounded-lg shadow-lg" />
            <p className="text-xl text-center max-w-2xl">{bookData.userStory}</p>
          </>,
          'bg-green-100'
        )}

        {bookData.familyMembers && bookData.familyMembers.map((member, index) => renderPage(
          <>
            <img src={member.image} alt={`Family Member ${index + 1}`} className="max-w-md mb-8 rounded-lg shadow-lg" />
            <p className="text-xl text-center max-w-2xl">{member.story}</p>
          </>,
          index % 2 === 0 ? 'bg-pink-100' : 'bg-purple-100'
        ))}

        {renderPage(
          <>
            <img src={bookData.finalChildImage} alt="Final Child" className="max-w-md mb-8 rounded-lg shadow-lg" />
            <p className="text-xl text-center max-w-2xl">{bookData.finalStory}</p>
          </>,
          'bg-orange-100'
        )}

        {renderPage(
          <p className="text-xl italic text-center max-w-2xl">{bookData.personalNote}</p>,
          'bg-indigo-100'
        )}
      </div>
    );
  }
};

export default SharedBookPage;